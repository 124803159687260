.HomeContainer {
  display: flex;
  align-items: center;
  justify-content: top;
  flex-direction: column;
  background-repeat: repeat-x;
  background-position: 0 50px;
  background-size: cover;
  background-origin: border-box;
  background-image: url('../../images/background-image.svg');
}

.randomPoapsTitle {
  font-size: 8rem;
  font-weight: bold;
  text-align: center;
  margin-top: 163px;
  margin-bottom: 10px;
  background-image: linear-gradient(to left, #6534ff, #b393d3);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.randomPoapsDescription {
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
  margin: 0px 20px 75px 20px;
}

@media (max-width: 802px) {
  .HomeContainer {
    background-image: none;
  }

  .randomPoapsTitle {
    font-size: 6rem;
    margin-bottom: 25px;
  }

  .randomPoapsDescription {
    font-size: 2rem;
  }
}
