.winnersListContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 25px;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 25px;
}

.winnersListContainer::-webkit-scrollbar {
  background-color: #161616;
  border-radius: 5px;
  width: 10px;
  height: 10px;
}

.winnersListContainer::-webkit-scrollbar-thumb {
  background-color: rgb(72, 72, 72);
  border-radius: 5px;
}

@media (max-width: 802px) {
  .winnersListContainer {
    width: 80%;
    max-height: 40%;
    overflow-x: auto;
  }
}
