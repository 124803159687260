.singleEventContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.eventImg {
  width: 12rem;
  height: 12rem;
  mask-position: center;
}

.eventImg:hover {
  opacity: 75%;
  mask-image: url('../../images/xmark-solid.svg');
  mask-repeat: no-repeat;
  mask-size: 8rem;
}

.eventImg:active {
  transition: all 0.1s ease 0s;
  mask-size: 6rem;
}

.eventName {
  width: 12rem;
  font-size: 1.5rem;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 1; /* Waiting for standarization? */
  -webkit-box-orient: vertical;
  margin: 8px 0 8px 0;
}

.event-placeholder {
  animation: event-placeholder 0.25s linear infinite alternate;
}

@keyframes event-placeholder {
  0% {
    background-color: rgb(40, 40, 40);
  }

  100% {
    background-color: rgb(50, 50, 50);
  }
}

.ph-image {
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  margin-bottom: 0.25rem;
}

.ph-name {
  width: 192px;
  height: 1em;
  border-radius: 0.5em;
  margin: 0.25rem 0 0.25rem 0;
}
