.singleWinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 25px;
}

.winner-number {
  margin: 0 10px 0 10px;
  user-select: none;
}

.winner-address {
  margin: 0 10px 0 10px;
  text-decoration: none;
}

.winner-address:hover {
  color: #6534ff;
}

.winner-address:active {
  color: #3a0ec8;
}

.winner-copy {
  margin: 0 10px 0 5px;
  cursor: pointer;
}

.winner-copy:hover {
  opacity: 80%;
}

.winner-copy:active {
  opacity: 60%;
}
