@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

* {
    font-family: 'Roboto Condensed', sans-serif;
    color: #fff;
}

html, .page {
    background-color: #000001;
}

.Toastify__toast {
    --toastify-icon-color-info: #fff;
    --toastify-color-dark: #6534FF;
    border-radius: 10px !important;
}

@media (max-width: 480px) {
    .Toastify__toast {
        border-radius: 0 !important;
    }
}